<template>
  <div id="portfolioContainer">
      <div class="portfolioGrid">
        <div class="item1" :style="{backgroundImage: 'url(' + require('./../assets/portfolioImage.png') + ')'}" @click="$emit('changeRoute', {name: 'interhigh-school'})">
          <div>
            <div>
              <h1>Interhigh Campaign</h1>
              <span>Advertising & Animation</span>
              <img src="https://ik.imagekit.io/nnol5se8mol/tr:h-50,w-50/downbtn_LWLlPOQ9nq.png">
            </div>
          </div>
        </div>
        <div class="item2"><h1>Project 2</h1></div>
        <div class="item3"><h1>Project 3</h1></div>
        <div class="item4"><h1>Project 4</h1></div>
        <div class="item5"><h1>Project 5</h1></div>
      </div> 
    <footerComp/>
  </div>
</template>

<style lang="sass">

$color1: rgba(38, 70, 83, 1)
$color2: rgba(42, 157, 143, 1)
$color3: rgba(233, 196, 106, 1)
$color4: rgba(244, 162, 97, 1)
$color5: rgba(231, 111, 81, 1)

#portfolioContainer
  background-color: $color1
  width: 100vw
  position: absolute
  left: 0
  top: 0
  .portfolioGrid
    padding-top: 120px
    width: 100vw
    height: 100vh
    display: grid
    grid-template-areas: 'item1 item2 item4' 'item3 item2 item5'
    @media (max-width: 1100px)
      padding-top: 0
      grid-template-areas: 'item1' 'item2' 'item3' 'item4' 'item5'
      height: auto
      div
        height: 50vh
        &:nth-child(2)
          height: 100vh
    div
      background-size: cover
      background-repeat: no-repeat
      background-position: center
      &:hover
        div
          opacity: 1 !important
          h1
            transform: translate(0, 0) !important
          span
            transform: translate(0, 0) !important
      div
        height: 100%
        width: 100%
        opacity: 0
        background-color: #2F0167
        transform-origin: left
        transition: all 0.5s ease
        div
          height: 100%
          width: 100%
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          h1
            transition: all 0.5s ease
            transform: translate(0, -50px)
          span
            transition: all 0.5s ease
            transform: translate(0, -50px)
            font-style: italic
          img
            width: 40px
            height: 40px
            margin-top: 20px
            animation: float-x 2.5s ease-in-out infinite
    .item1
      grid-area: item1
    .item2
      grid-area: item2
      background: $color3
    .item3
      grid-area: item3
      background: $color4
    .item4
      grid-area: item4
      background: $color5
    .item5
      grid-area: item5
      background: $color2
</style>

<script>
export default {
  //VueMeta
  //name: 'Portfolio',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Selected Works'
  },
  mounted() {
    document.body.style.height = "auto"
    window.scrollTo(0, 0)
  },

  destroyed() {
    document.body.style.height = "1000vh"
  }
}
</script>